import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import Icon from 'components/Icon';
import Button from 'components/Button';
import Close from 'components/Close';
import { getThemeColor, getThemeSpacePx, device, px } from 'utils/css-utils';
import useMenuHeight from 'hooks/useMenuHeight';

import DatablocksLogo from 'assets/images/datablocks-logo.svg';

const Centered = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
`;

const Wrapper = styled.nav`
  flex-shrink: 0;
`;

const NavWrapper = styled(Box)`
  position: absolute;
  display: ${(p) => (p.menuOpen ? 'flex' : 'none')};
  height: ${(p) => px(p.height)};
  top: 0;
  left: 0;
  width: 100%;
  padding: ${getThemeSpacePx(3)};
  z-index: 500;
  background: ${getThemeColor('background')};
  align-items: center;
  flex-direction: column;

  .desktop {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
    height: auto;
    position: relative;
    padding: 0;
    margin-left: auto;
    width: auto;
    background: transparent;
    align-items: unset;
    flex-direction: row;

    .desktop {
      display: inline-block;
    }

    .mobile {
      display: none;
    }
  }
`;

const Nav = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const NavInner = styled.div`
  line-height: 1;
  width: 100%;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
  }
`;

const NavItem = styled(Link)`
  text-decoration: none;
  font-size: 30px;
  display: block;
  padding: ${(p) => (p.isButton ? '8px 16px' : '16px 0')};
  text-align: center;
  color: ${getThemeColor('textLight')};

  &:focus,
  &:visited,
  &:active {
    color: ${getThemeColor('textLight')};
  }

  @media ${device.tablet} {
    margin-right: 50px;
    font-size: 16px;
    display: inline-block;
    padding: ${(p) => (p.isButton ? '8px 16px' : 0)};
    color: ${getThemeColor('textLight')};

    &:focus,
    &:visited {
      color: ${getThemeColor('textLight')};
    }

    &.active,
    &:hover {
      color: ${getThemeColor('text')};
    }
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-size: 30px;
  display: block;
  padding: ${(p) => (p.isButton ? '8px 16px' : '16px 0')};
  text-align: center;
  color: ${getThemeColor('textLight')};

  &:focus,
  &:visited,
  &:active {
    color: ${getThemeColor('textLight')};
  }

  @media ${device.tablet} {
    margin-right: 50px;
    font-size: 16px;
    display: inline-block;
    padding: ${(p) => (p.isButton ? '8px 16px' : 0)};
    color: ${getThemeColor('textLight')};

    &:focus,
    &:visited {
      color: ${getThemeColor('textLight')};
    }

    &.active,
    &:hover {
      color: ${getThemeColor('text')};
    }
  }
`;

const NavButton = styled.div`
  cursor: pointer;

  @media ${device.tablet} {
    display: none;
  }
`;

const Image = styled.img`
  display: block;
  width: 40px;
  margin-right: 12px;
`;

const LogoTitle = styled(Box)`
  font-weight: 900;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  color: ${getThemeColor('text')};
`;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuHeight = useMenuHeight();

  const toggleMenu = () => {
    const nextMenuOpen = !menuOpen;

    document.body.classList.toggle('noscroll', nextMenuOpen);

    setMenuOpen(nextMenuOpen);
  };

  const handleSignupButton = () => {
    const signupSection = document.getElementById('signup');

    if (signupSection) {
      signupSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Wrapper>
      <Centered>
        <Flex alignItems="center" as={Link} to="/">
          <Image src={DatablocksLogo} alt="datablocks logo" />
          <Box>
            <LogoTitle>datablocks</LogoTitle>
          </Box>
        </Flex>
        <NavButton onClick={toggleMenu}>
          <Icon name="menu" strokeColor="text" colorizeStroke />
        </NavButton>
        <NavWrapper menuOpen={menuOpen} height={menuHeight}>
          <Nav>
            <NavInner>
              <NavItem
                onClick={closeMenu}
                to="/"
                activeClassName="active"
                className="mobile"
              >
                Home
              </NavItem>

              <NavLink
                href="https://webkid.io/blog/datablocks-node-based-editor-data-processing-visualization/"
                onClick={closeMenu}
              >
                About
              </NavLink>

              <NavLink
                href="https://editor.datablocks.pro/demo"
                onClick={closeMenu}
              >
                Demo
              </NavLink>

              <Button
                activeClassName="active"
                className="desktop"
                isButton
                onClick={closeMenu}
                color="red"
                type="big"
                as="a"
                href="https://editor.datablocks.pro/signup"
              >
                Sign Up
              </Button>
            </NavInner>
          </Nav>
          <Close onClick={toggleMenu} className="mobile" />
        </NavWrapper>
      </Centered>
    </Wrapper>
  );
};

export default Header;
